<template>
  <div class="order-analyzes">
    <el-row class="order-analyze-title">
      <el-col :span="3">
        <span class="page-title">趋势分析</span>
      </el-col>
      <el-col :span="21" class="order-analyze-list-filter">
      </el-col>
    </el-row>

    <div class="order-analyze-data-list">
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="order-analyze-info-item">
            <span class="h4">交易额</span>
            <div class="analyze-info-result">
              <p class="info-data"><span class="info-data-number">{{(totalSale/100)  | F1000}}</span> 元</p>
              <p class="info-data-calc" v-if="deviceCnt > 0">单台设备平均交易额:
                {{((totalSale / deviceCnt) / 100) | F1000}}
                元</p>
              <p class="info-data-calc" v-else>单天设备平均交易额: 0 元</p>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="order-analyze-info-item">
            <span class="h4">设备数</span>
            <div class="analyze-info-result">
              <p class="info-data"><span class="info-data-number">{{deviceCnt | I1000}}</span> 台</p>

            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="order-analyze-info-item">
            <span class="h4">制作杯量</span>
            <div class="analyze-info-result">
              <p class="info-data">

                <span class="info-data-number">{{totalOrder | I1000}}</span> 杯</p>
              <p class="info-data-calc" v-if="deviceCnt > 0">单台设备平均制作杯数:
                {{(totalOrder / deviceCnt) | F1000}} </p>
              <p class="info-data-calc" v-else>单台设备平均制作杯数: 0 </p>
            </div>
          </div>
        </el-col>
      </el-row>

      <div class="chart-30">
        <p class="item-title">近三十天成交额</p>
        <div class="chart" id="line30Chart">
        </div>
      </div>
      <div class="style">
        <pay-style-pie class="top10-chart" ref="payPie"></pay-style-pie>
      </div>
      <div class="seg"/>
    </div>

  </div>

</template>

<script>
  import * as echarts from 'echarts'
  // import PayStylePie from '@/components/PayStylePie'
  // import {deviceSearch, deviceCnt} from '@/api/mkt/order'
  // import {orderAnalyze} from '@/api/delivery/order/order'
  // import {groupAll} from '@/api/delivery/organization/group'

  export default {
    name: '',
    components: {
      // PayStylePie
    },
    mounted: function () {
      this.init()
      // groupAll().then(res => {
      //   this.groups = res.data || []
      // })
      this.loadDevice()
    },
    data: function () {
      return {
        groups: [],
        loading: false,
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0, 0, 0, 0)
              end.setHours(23, 59, 59, 0)
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              start.setHours(0, 0, 0, 0)
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              end.setHours(23, 59, 59, 0)

              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一年',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        makeTimeRange: [],
        line30Option: {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            orient: 'horizontal', // 'vertical'
            x: 'right', // 'center' | 'left' | {number},
            y: 'top', // 'center' | 'bottom' | {number}
            padding: [5, 100, 15, 20],    // [5, 10, 15, 20]
            itemGap: 20,
            textStyle: {
              color: '#BAC3CE',
            }
          },
          grid: {
            top: '18%',
            right: '4%',
            left: '6%',
            bottom: '14%',
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              alignWithLabel: true
            },
            axisLine: {
              lineStyle: {
                color: '#E2E2E2'
              }
            },
            axisLabel: {
              color: '#E2E2E2'
            },
            data: []
          },
          yAxis: [
            {
              type: 'value',
              name: this.$t("bei_liang"),
              axisLine: {
                // show: false,
                lineStyle: {
                  color: '#E2E2E2'
                }
              },
              axisTick: {
                show: false,
              },
              // nameTextStyle: {
              //   color: '#BAC3CE',
              //   fontWeight: '600'
              // },
              // splitLine: {
              //   show: true,
              //   lineStyle: {
              //     color: "#2B3139",
              //     type: 'dotted',
              //   },
              // },
              splitLine: {
                show: true,
                lineStyle: {
                  color: '#e2e2e2',
                  type: 'dashed'
                }
              },

              axisLabel: {
                formatter: '{value}',
                color: '#E2E2E2',
              }
            },
            {
              type: 'value',
              name: this.$t("jin_e"),
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              nameTextStyle: {
                color: '#E2E2E2',
                fontWeight: '600'
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: "#2B3139",
                  type: 'dotted',
                },
              },

              axisLabel: {
                formatter: '{value}',
                color: '#E2E2E2',
              }
            }
          ],
          series: [
            {
              name: this.$t('zhi_zuo_bei_shu'),
              type: 'line',
              smooth: true,
              lineStyle: {
                normal: {
                  width: 1
                }
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                    offset: 0,
                    color: 'rgba(7,44,90,0.3)'
                  }, {
                    offset: 1,
                    color: 'rgba(0,212,199,0.9)'
                  }]),
                  shadowColor: 'rgba(0, 0, 0, 0.1)',
                  shadowBlur: 10
                }
              },
              itemStyle: {
                normal: {
                  color: 'rgb(0,212,199)'

                }
              },

              data: [],
              // symbolSize: 2,
              // itemStyle: {
              //   normal: {
              //     color: '#E49831',
              //     lineStyle: {
              //       color: '#E49831',
              //     }
              //   }
              // },
            },
            {
              name: this.$t('cheng_jiao_jin_e'),
              type: 'line',
              data: [],
              yAxisIndex: 1,
              symbolSize: 2,
              // itemStyle: {
              //   normal: {
              //     color: '#E85655',
              //     lineStyle: {
              //       color: '#E85655',
              //     }
              //   }
              // },
              smooth: true,
              lineStyle: {
                normal: {
                  width: 1
                }
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                    offset: 0,
                    color: 'rgba(7,44,90,0.3)'
                  }, {
                    offset: 1,
                    color: 'rgba(0,146,246,0.9)'
                  }]),
                  shadowColor: 'rgba(0, 0, 0, 0.1)',
                  shadowBlur: 10

                }
              },
              itemStyle: {
                normal: {
                  color: 'rgb(0,146,246)'
                }
              },
            },
          ]
        },
        pagination: {
          startAt: 0,
          endAt: 0,
          groupUuid: '',
          deviceUuid: '',
        },
        devices: [],
        deviceCnt: 0,
        deviceCntKeep: 0,
        myChart: null,
        totalSale: 0,
        totalOrder: 0
      }
    },
    methods: {
      loadDevice() {
        // deviceCnt({groupUuid: this.pagination.groupUuid}).then(res => {
        //   if (res.data) {
        //     this.deviceCnt = parseInt(res.data)
        //     this.deviceCntKeep = this.deviceCnt
        //     this.loadOrderAnalyze()
        //   }
        // })
      },
      deviceNameSearch(query) {
        if (query !== '') {
          this.loading = true;
          // deviceSearch({name: query}).then(res => {
          //   this.devices = res.data || []
          //   this.loading = false;
          // })
        } else {
          this.devices = [];
        }
      },
      loadSingleDeviceOrderAnalyze() {
        if (this.pagination.deviceUuid) {
          this.deviceCnt = 1
        } else {
          this.deviceCnt = this.deviceCntKeep
        }


        this.loadOrderAnalyze()

      },
      loadOrderAnalyze() {
        if (this.makeTimeRange.length === 0) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          this.makeTimeRange = [start, end];
        }
        let ts = []

        let startTs = this.makeTimeRange[0].getTime()

        // let startAt = parseInt(startTs / 1000)
        let endTs = this.makeTimeRange[1].getTime()
        let oneDay = 3600 * 1000 * 24

        let index = {}
        let orders = []
        let sales = []

        let i = 0;
        // eslint-disable-next-line no-constant-condition
        while (true) {
          if (startTs > endTs) {
            break
          }
          let d = new Date(startTs).Format('yyyy-MM-dd')
          index[d] = i++;
          ts.push(d)
          orders.push(0)
          sales.push(0)
          startTs += oneDay
        }

        // let totalSale = 0
        // let totalOrder = 0
        // let totalPayOrder = 0
        // orderAnalyze({
        //   groupUuid: this.pagination.groupUuid,
        //   deviceUuid: this.pagination.deviceUuid,
        //   startAt: startAt,
        //   endAt: parseInt(endTs / 1000),
        // }).then(res => {
        //   let saleInfos = res.data || []
        //   saleInfos.forEach(item => {
        //
        //     if (item.month < 10) {
        //       item.month = '0' + item.month
        //     }
        //     if (item.day < 10) {
        //       item.day = '0' + item.day
        //     }
        //
        //     let key = index[`${item.year}-${item.month}-${item.day}`]
        //     console.log(key)
        //     totalSale += item.sale
        //     totalOrder += item.orders
        //     totalPayOrder += item.payOrder
        //     orders[key] = item.orders
        //     sales[key] = (item.sale) / 100
        //   })
        //   this.line30Option.xAxis.data = ts
        //   this.line30Option.series[0].data = orders
        //   this.line30Option.series[1].data = sales
        //   this.myChart.setOption(this.line30Option)
        //   this.totalSale = totalSale
        //   this.totalOrder = totalOrder
        //   this.$refs.payPie.setOrder(totalPayOrder, totalOrder - totalPayOrder)
        // })
      },
      init() {
        this.myChart = echarts.init(document.getElementById('line30Chart'));
        this.myChart.setOption(this.line30Option)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .order-analyzes {
    padding: $container-padding;
    display: flex;
    flex-direction: column;

    .order-analyze-list-filter {
      .el-range-input {
        border: none;
      }

      input {
        border: none;
        border-bottom: 1px solid $content-border-color;
        @include font-little();
        border-radius: 0;
      }
    }

    .order-analyze-data-list {
      margin-top: $middle-space;
      flex: 1;

      .order-analyze-info-item {
        border: 1px solid $content-border-color;
        padding: $middle-space;
        border-radius: .5rem;
        height: 8rem;
        display: flex;
        flex-direction: column;
        text-align: center;

        .h4 {
          text-align: left;
          font-size: .8rem;
          font-weight: 600;
          display: inline-block;
          margin-bottom: $middle-space;
        }

        .analyze-info-result {
          flex: 1;
          justify-content: center;
          align-items: center;

          .info-data {
            margin-bottom: $middle-space;

            .info-data-number {
              font-size: 1.6rem;
              // font-weight: 600;

            }

          }

          .info-data-calc {
            font-size: .6rem;
          }
        }
      }

      .item-title {
        font-size: .8rem;
        font-weight: 600;
        display: inline-block;
        margin-bottom: $middle-space;
      }

      .chart-30 {
        margin-top: $middle-space;
        border-radius: .5rem;

        .chart {
          height: 20rem;
        }
      }

      .top10 {
        display: flex;
        margin-top: $middle-space;
        border-radius: .5rem;

        .top10-chart {
          height: 20rem;
          flex: 1;

        }
      }

      .style {
        display: flex;
        margin-top: $middle-space;
        border-radius: .5rem;

        .top10-chart {
          height: 15rem;
          flex: 1;

        }
      }

      .seg {
        height: $middle-space;
        width: 100%;
      }

      .seg-width {
        width: $middle-space;
        height: 100%;
      }
    }
  }

</style>
